import { api } from 'utils/fetch';

export async function poaChunkUpload(body: FormData) {
  return await api.post(`/external/UploadChunks`, body);
}

export const postUploadCompleteFileNameMeetingId = async (
  fileGuid: string,
  meetingAttendeeId: number,
  meetingId: number,
  pin: string,
  fileName: string
) => {
  try {
    await api.get(
      `/external/UploadComplete/${fileGuid}/${meetingAttendeeId}/${meetingId}/${pin.replace('-', '')}/${fileName}`
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};
